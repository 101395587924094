<template>
  <div v-if="dashboardData">
    <el-row class="panel">
      <el-col :span="4">
        <div class="block">
          <span style="font-weight: bold;">Эхлэх огноо:</span>
          <el-date-picker
            v-model="start_date"
            type="datetime"
            placeholder="Эхлэх хугацаа"
            @change="test()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <span style="font-weight: bold;">Дуусах огноо:</span>
          <el-date-picker
            v-model="end_date"
            type="datetime"
            placeholder="Дуусах хугацаа"
            @change="test()"
          >
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="panel" style="gap: 30px; flex-wrap: wrap">
      <!-- Client -->
      <el-row>
        <el-row class="mb10" type="flex" align="middle">
          <span class="header-class">Client</span>
          <div class="total-class">{{ dashboardData.total_clients }}</div>
        </el-row>
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Clients</th>
              <th>Stories ({{ dashboardData.total_stores }})</th>
              <th>
                New
                <i
                  class="el-icon-circle-check"
                  style="color: green; font-size: 16px; font-weight: bold"
                ></i>
              </th>
              <th>
                New
                <i
                  class="el-icon-error"
                  style="color: red; font-size: 16px; font-weight: bold"
                ></i>
              </th>
              <th>Removed</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dashboardData.all_clients" :key="index">
              <td class="m_title">{{ item.service }}</td>
              <td>{{ item.count }}</td>
              <td>{{ item.store_count }}</td>
              <td>{{ item.new_verified }}</td>
              <td>{{ item.new_unVerified }}</td>
              <td>{{ item.removed }}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
      <!-- Active stores -->
      <el-row>
        <el-row class="mb10" type="flex" align="middle">
          <span class="header-class">Active stores</span>
          <div class="total-class">{{ dashboardData.total_active_stores }}</div>
        </el-row>
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Stores</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index2) in dashboardData.active_stores"
              :key="index2"
            >
              <td class="m_title">{{ item.service }}</td>
              <td>{{ item.tr }}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
      <!-- TPS -->
      <el-row>
        <el-row class="mb10" type="flex" align="middle">
          <span class="header-class">TPS</span>
          <div class="total-class">
            {{ dashboardData.total_tps }}
          </div>
        </el-row>
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>TPS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index2) in dashboardData.tps" :key="index2">
              <td class="m_title">{{ item.service }}</td>
              <td>{{ item.tps }}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
      <!-- Products -->
      <el-row>
        <el-row class="mb10" type="flex" align="middle">
          <span class="header-class">Products</span>
          <div class="total-class">{{ dashboardData.total_products }}</div>
        </el-row>
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Open</th>
              <th>Closed</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index2) in dashboardData.products" :key="index2">
              <td class="m_title">{{ item.service }}</td>
              <td>{{ item.active_product }}</td>
              <td>{{ item.passive_product }}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
    </el-row>
  </div>
</template>
<script>
import service from "../helpers/services";
export default {
  name: "SuggestList",
  created() {
    this.dashboard();
    this.getOutletsWorkingHours();
  },
  data() {
    return {
      dashboardData: null,
      start_date: "",
      end_date: "",
      timetable_data: null,
      loading: null
    };
  },
  methods: {
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн мэдээлэл татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    test() {
      if (this.start_date !== "" && this.end_date !== "") {
        this.dashboard();
      }
    },
    dashboard() {
      this.openFullScreenLoader(true);
      const payload = {
        start_date: this.start_date,
        end_date: this.end_date
      };
      service.dashboard(payload).then(response => {
        if (response.data.status === "success") {
          this.dashboardData = response.data.data;
          this.openFullScreenLoader(false);
        } else {
          this.openFullScreenLoader(false);
          this.$notify({
            title: "Мэдэгдэл",
            message: response.data.message,
            type: response.data.status
          });
        }
      });
    },
    getOutletsWorkingHours() {
      const payload = {
        start_date: this.start_date,
        end_date: this.end_date
      };
      service.getOutletsWorkingHours(payload).then(response => {
        this.timetable_data = response.data;
      });
    }
  }
};
</script>
<style scoped>
table {
  width: auto;
  background: #f8f9fb;
  border: 1px solid #e7eaee;
  border-radius: 12px;
  border-spacing: 0;
}
table th {
  padding: 20px;
  border-bottom: 1px solid #e7eaee;
}
table td {
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
table td:first-child {
  text-align: left;
}
table thead tr:hover {
  background: #f8f9fb;
}
table tbody {
  cursor: pointer;
}
table tr:hover {
  background: #e5ecf3;
  border-radius: 12px;
}
.m_title::first-letter {
  text-transform: uppercase;
}
.header-class {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
}
.total-class {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  background-color: #f8f9fb;
  border-radius: 10px;
  margin-left: 15px;
}
</style>
